
import styled from "styled-components";
import WrapperPage from "../../components/wrapper-page";
import { FaFilePdf } from "react-icons/fa";
import { HiDownload, HiSearch } from "react-icons/hi";
import Wrapper from "../../components/wrapper";
import Box from "../../components/box";
import { Helmet } from 'react-helmet';

export const Content = styled.div`
    padding: 48px 0;

    @media(max-width: 900px){
        padding: 0;
    }
`;

const Titulo = styled.h1`
    padding-bottom: 24px;

    @media(max-width: 900px){
        font-size: 24px;
    }
`;


const List = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    align-items: start;
    width: 80%;

    @media (max-width: 1200px){
        width: 100%;
    }

    @media(max-width: 900px){
        align-items: start;
    }   
`;

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    border-bottom: 1px solid #ccc;
    padding: 18px;

    :first-child{
        min-width: 24px;
    }

    :last-child{
        border-bottom: initial;
    }

    @media(max-width: 900px){
        width: 100%;
        padding: 12px;
    }   

    @media(max-width: 600px){
        padding: 8px 0;
        gap: 6px;
    }
`;

const IconWrapper = styled.div`
    width: 24px;
`;

const Download = styled.a``;

const Search = styled.a``;

const NumberItems = styled.div`
    padding-top: 48px;
    text-align: right;
`;

const Name = styled.div`
    font-weight: bold;
    min-width: 600px;

    @media(max-width: 900px){
        min-width: initial;
    }

    @media(max-width: 600px){
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`;

const Size = styled.div`
    @media(max-width: 900px){
        margin-left: auto;
    }

    @media(max-width: 600px){
        font-size: 14px;
    }
`;


function DocumentacionEstrategica() {

    return (
        <Content>
            <Helmet>
                <title>Documentació estratègica</title>
                <meta name="description" content="Documentació estratègica" />
            </Helmet>
            <WrapperPage>
                <Wrapper>
                    <Titulo>Documentació estratègica</Titulo>
                    <List>
                        <Box>
                            <Item>
                                <IconWrapper>
                                    <FaFilePdf color="#dc3545" size="24px" />
                                </IconWrapper>
                                <Name>Carta de serveis</Name>
                                <Size>5.5MB</Size>
                                <Search
                                    target='_blank'
                                    href='https://drive.google.com/file/d/1CNzFVbOhe0ISWLHxQsrQqvl13EGY3Dlc/view?usp=drive_link'>
                                    <HiSearch color='#343a40' size="1.5em" />
                                </Search>
                                <Download
                                    download='carta_serveis.pdf'
                                    href='https://drive.google.com/uc?export=download&id=1CNzFVbOhe0ISWLHxQsrQqvl13EGY3Dlc'>
                                    <HiDownload color='#343a40' size="1.5em" />
                                </Download>
                            </Item>
                            <Item>
                                <IconWrapper>
                                    <FaFilePdf color="#dc3545" size="24px" />
                                </IconWrapper>
                                <Name>PEC (Projecte Educatiu de Centre)</Name>
                                <Size>889KB</Size>
                                <Search
                                    target='_blank'
                                    href='https://drive.google.com/file/d/1idptn-8LfYKDYNnBg5TwWiBv2CySxZf6/view?usp=sharing'>
                                    <HiSearch color='#343a40' size="1.5em" />
                                </Search>
                                <Download
                                    download='pec.pdf'
                                    href='https://drive.google.com/file/d/1idptn-8LfYKDYNnBg5TwWiBv2CySxZf6'>
                                    <HiDownload color='#343a40' size="1.5em" />
                                </Download>
                            </Item>
                            <Item>
                                <IconWrapper>
                                    <FaFilePdf color="#dc3545" size="24px" />
                                </IconWrapper>
                                <Name>PLC (Projecte Lingüístic de Centre)</Name>
                                <Size>953KB</Size>
                                <Search
                                    target='_blank'
                                    href='https://drive.google.com/file/d/1iRiVa0nNgg5gRkPU5HaOlqfUF-sonbeE/view?usp=drive_link'>
                                    <HiSearch color='#343a40' size="1.5em" />
                                </Search>
                                <Download
                                    download='plc.png'
                                    href='https://drive.google.com/uc?export=download&id=1iRiVa0nNgg5gRkPU5HaOlqfUF-sonbeE'>
                                    <HiDownload color='#343a40' size="1.5em" />
                                </Download>
                            </Item>
                            <Item>
                                <IconWrapper>
                                    <FaFilePdf color="#dc3545" size="24px" />
                                </IconWrapper>
                                <Name>NOFC (Normes d'Organització i Funcionament del Centre)</Name>
                                <Size>1.5MB</Size>
                                <Search
                                    target='_blank'
                                    href='https://drive.google.com/file/d/1u64BHxmM25QZ_-ESQ4wVtJx28Qb7YddG/view?usp=sharing'>
                                    <HiSearch color='#343a40' size="1.5em" />
                                </Search>
                                <Download
                                    download='npfc.png'
                                    href='https://drive.google.com/file/d/1u64BHxmM25QZ_-ESQ4wVtJx28Qb7YddG'>
                                    <HiDownload color='#343a40' size="1.5em" />
                                </Download>
                            </Item>
                            <NumberItems>4 documents trobats</NumberItems>
                        </Box>
                    </List>
                </Wrapper>
            </WrapperPage>
        </Content>
    );
}

export default DocumentacionEstrategica;