import styled from "styled-components";
import Wrapper from "../wrapper";
import Box from "../box";
import { useContext } from "react";
import { MainContext } from "../../context/main-provider";


const Content = styled.div``;

const Title = styled.h2`
  display: flex; 
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-align: center;
  font-weight: bold; 
  color: #dd6b0c;

  @media(max-width: 1024px){
    font-size: 28px;
    line-height: 28px;
  }

  @media(max-width: 600px){
    font-size: 22px;
    line-height: 22px;
  }
`;

const Info = styled.div` 
  text-align: center;
  font-size: 18px;
  color: #343a40;
  max-width: 900px;
  margin: 16px auto;

  @media(max-width: 920px){
    padding-bottom: 21px;
  }

  @media(max-width: 600px){
    font-size: 14px;
    padding-bottom: 0;
    text-align: left;
  }
`;

const Sections = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
  padding: 18px 0;
  margin: 0 auto;

  @media(max-width: 930px){
    flex-direction: column;
  }

  @media(max-width: 600px){
    padding: 0;
    margin: 0 auto 24px;
  }
`;

const Section = styled.div`
  flex: 1 1 calc(50% - 12px); 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  border-radius: 24px;
  padding: 12px 24px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.07);
  background-color: #fff;
  cursor: pointer;

  @media(max-width: 1024px){
    padding: 18px;
  }

  @media(max-width: 600px){
    padding: 12px 24px;
  }

  @media(max-width: 400px){
    padding: 12px;
  }
`;

const SectionTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: left;

  @media(max-width: 600px){
    font-size: 16px;
  }
`;

const SectionStatus = styled.div`
  display: flex; 
  flex-direction: row;
`;

const SectionDate = styled.div`
  font-size: 16px;

  @media(max-width: 600px){
    font-size: 14px;
  }

  @media(max-width: 400px){
    font-size: 12px;
  }
`;

const WrapperBox = styled.div`
  padding: 16px 0;
`;

function PortesObertes() {
  const inici = useContext(MainContext);
  if (inici === null ||
    (!inici.PortesObertesESO &&
      !inici.PortesObertesBatxillerat &&
      !inici.PortesObertesCFGM &&
      !inici.PortesObertesCFGS)) return <></>;

  return (
    <Content>
      <Wrapper>
        <Box backgroundColor="#ffe5cf">
          <WrapperBox>
            <Title>Jornada de Portes Obertes curs  {new Date().getFullYear()}/{new Date().getFullYear() + 1}</Title>
            <Info>Vine a visitar-nos! Us hi esperem!</Info>
            <Sections>
              <Section>
                <SectionTitle>ESO</SectionTitle>
                <SectionStatus>
                  {inici.PortesObertesESO
                    ? <SectionDate>{inici.PortesObertesESO}</SectionDate>
                    : <SectionDate>Properament</SectionDate>
                  }
                </SectionStatus>
              </Section>
              <Section >
                <SectionTitle>Batxillerat</SectionTitle>
                {inici.PortesObertesBatxillerat
                  ? <SectionDate>{inici.PortesObertesBatxillerat}</SectionDate>
                  : <SectionDate>Properament</SectionDate>
                }
              </Section>
              <Section >
                <SectionTitle>CFGM</SectionTitle>
                {inici.PortesObertesCFGM
                  ? <SectionDate>{inici.PortesObertesCFGM}</SectionDate>
                  : <SectionDate>Properament</SectionDate>
                }
              </Section>
              <Section>
                <SectionTitle>CFGS</SectionTitle>
                {inici.PortesObertesCFGM
                  ? <SectionDate>{inici.PortesObertesCFGS}</SectionDate>
                  : <SectionDate>Properament</SectionDate>
                }
              </Section>
            </Sections>
          </WrapperBox>
        </Box>
      </Wrapper>
    </Content >
  );
}

export default PortesObertes;